import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contract = ({location}) => (
  <Layout>
    <Helmet>
      <script
        src="https://kit.fontawesome.com/0842dd585c.js"
        crossorigin="anonymous"
      ></script>
    </Helmet>
    <SEO title="Umowy" />
    <div className="container">
      <div className="main">
        <ol>
          <li><Link to="/">Strona główna</Link> / <Link to="/contract">Umowy</Link></li>
        </ol>
        <h1>Umowy:</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-4 box__download">
            <a href="https://partnerzy.fototransfer.pl/assets/pdf/umowy_o_wsp__pracy_ekspozycja_2.pdf">
                <h4><i className="fas fa-file-pdf"></i> Umowa o współpracy handlowej</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default Contract
